import 'react-phone-number-input/style.css'
import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { Outlet } from 'react-outlet'
import PhoneInput from 'react-phone-number-input'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import routes from '../../../config/routes'
import { UsagePlan } from '../../../config/usagePlans'
import { showAlert } from '../../../modules/alert/actions'
import { RequestStatus } from '../../../modules/api'
import useBillingInfo, { BillingInfo } from '../../../modules/api/auth-service/billingInfo'
import { BillingSource } from '../../../modules/api/auth-service/billingSources'
import useContactInfo from '../../../modules/api/auth-service/contactInfo'
import { tx } from '../../../modules/translate'
import { getErrorMessage } from '../../../utils/error'
import { initializeForm } from '../../../utils/form'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import Button from '../../shared/Button'
import Modal, { ModalProps } from '../../shared/Modal'
import ModalBody from '../../shared/ModalBody'
import ModalClose from '../../shared/ModalClose'
import ModalHeader from '../../shared/ModalHeader'
import Spinner from '../../shared/Spinner'
import CurrentPlan from './CurrentPlan'
import PaymentMethods from './PaymentMethods'
import PlanCard from './PlanCard'
import Summary from './Summary'
import { UsagePlanItem } from './types'
import InfoBox, { InfoBoxType } from '../../shared/InfoBox'
import usePermissions from '../../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'
import { SessionCookieContents } from '../../../modules/cookies/sessionCookie'
import SessionCookie from '../../../modules/auth/session/cookie'
import BetaAnnouncement from '../../shared/BetaAnnouncement'
import { UserIdentityContext } from '../../../global-state/UserIdentityContext'

const freePlan: UsagePlanItem = {
  type: UsagePlan.free,
  name: 'Free',
  price: 0,
  offers: [
    'For individuals learning or building hobby apps',
    'Usage limits enforced',
    'Community support'
  ]
}

const individualPlan: UsagePlanItem = {
  type: UsagePlan.individual,
  name: 'Individual',
  price: 25,
  offers: [
    'For individuals building commercial apps',
    'Pay-as-you-go for usage beyond $25',
    'Community support'
  ]
}

const teamPlan: UsagePlanItem = {
  type: UsagePlan.team,
  name: 'Team',
  price: 150,
  offers: [
    'For developer teams building commercial apps',
    'Teams, Auth integration',
    'Help desk with 24 hr SLA'
  ]
}

const businessPlan: UsagePlanItem = {
  type: UsagePlan.business,
  name: 'Business',
  price: 500,
  offers: [
    'For developer teams building business-critical apps',
    'Teams, Auth integration',
    'Help desk with 8 hr SLA'
  ]
}

const freeV2Plan: UsagePlanItem = {
  type: UsagePlan['Free-V2'],
  name: 'Free',
  // placeholder fields
  price: 0,
  offers: [
    'For individuals learning or building hobby apps',
    'Usage limits enforced',
    'Community support'
  ]
}

const payAsYouGoPlan: UsagePlanItem = {
  type: UsagePlan['Pay-As-You-Go'],
  name: 'Pay-as-you-Go',
  // placeholder fields
  price: 25,
  offers: ['Same as the free plan without usage limits. Charges are based on your usage.']
}

const startupPlan: UsagePlanItem = {
  type: UsagePlan.Startup,
  name: 'Startup',
  // placeholder fields
  price: 150,
  offers: [
    'Up to 5 users',
    'No SLA',
    'Up to 100 DBs',
    'Backup and restore',
    'Basic throughput',
    'US and EU Region choice',
    'Monthly',
    'Standard support with Business support available'
  ]
}

const trialPlan: UsagePlanItem = {
  type: UsagePlan.Trial,
  name: 'Trial (Pro)',
  // placeholder fields
  price: 0,
  offers: [
    'Startup +',
    'Unlimited users',
    'Fauna Logs',
    'Unlimited DBs',
    'Higher throughput',
    '99.9% SLA',
    'Standard and Business support available'
  ]
}

const proPlan: UsagePlanItem = {
  type: UsagePlan.Pro,
  name: 'Pro',
  // placeholder fields
  price: 500,
  offers: [
    'Free trial available',
    'Startup +',
    'Unlimited users',
    'Fauna Logs',
    'Unlimited DBs',
    'Higher throughput',
    '99.9% SLA',
    'Standard and Business support available'
  ]
}

const enterpriseLow1k: UsagePlanItem = {
  type: UsagePlan['Enterprise-Low-1k'],
  name: 'Enterprise',
  price: 2500,
  offers: [
    'Pro +',
    'Custom Region Group',
    'HIPAA / BAAs',
    'Audit Logging',
    'Higher throughput',
    '99.95% SLA',
    'Business support included',
    'Premier and Premier Plus available'
  ]
}

const enterpriseLow1_5k: UsagePlanItem = {
  type: UsagePlan['Enterprise-Low-1_5k'],
  name: 'Enterprise',
  price: 2500,
  offers: [
    'Pro +',
    'Custom Region Group',
    'HIPAA / BAAs',
    'Audit Logging',
    'Higher throughput',
    '99.95% SLA',
    'Business support included',
    'Premier and Premier Plus available'
  ]
}

const enterpriseLow: UsagePlanItem = {
  type: UsagePlan['Enterprise-Low'],
  name: 'Enterprise',
  // placeholder fields
  price: 2500,
  offers: [
    'Pro +',
    'Custom Region Group',
    'HIPAA / BAAs',
    'Audit Logging',
    'Higher throughput',
    '99.95% SLA',
    'Business support included',
    'Premier and Premier Plus available'
  ]
}

const enterpriseMedium: UsagePlanItem = {
  type: UsagePlan['Enterprise-Medium'],
  name: 'Enterprise',
  // placeholder fields
  price: 2500,
  offers: [
    'Pro +',
    'Custom Region Group',
    'HIPAA / BAAs',
    'Audit Logging',
    'Higher throughput',
    '99.95% SLA',
    'Business support included',
    'Premier and Premier Plus available'
  ]
}

const enterpriseHigh: UsagePlanItem = {
  type: UsagePlan['Enterprise-High'],
  name: 'Enterprise',
  // placeholder fields
  price: 2500,
  offers: [
    'Pro +',
    'Custom Region Group',
    'HIPAA / BAAs',
    'Audit Logging',
    'Higher throughput',
    '99.95% SLA',
    'Business support included',
    'Premier and Premier Plus available'
  ]
}

export const usagePlans: Record<UsagePlan, UsagePlanItem> = {
  [UsagePlan.individual]: individualPlan,
  [UsagePlan.team]: teamPlan,
  [UsagePlan.free]: freePlan,
  [UsagePlan.business]: businessPlan,
  [UsagePlan['Free-V2']]: freeV2Plan,
  [UsagePlan['Pay-As-You-Go']]: payAsYouGoPlan,
  [UsagePlan.Startup]: startupPlan,
  [UsagePlan.Trial]: trialPlan,
  [UsagePlan.Pro]: proPlan,
  [UsagePlan['Enterprise-Low-1k']]: enterpriseLow1k,
  [UsagePlan['Enterprise-Low-1_5k']]: enterpriseLow1_5k,
  [UsagePlan['Enterprise-Low']]: enterpriseLow,
  [UsagePlan['Enterprise-Medium']]: enterpriseMedium,
  [UsagePlan['Enterprise-High']]: enterpriseHigh
}

const v1UsagePlans: Partial<Record<UsagePlan, UsagePlanItem>> = {
  [UsagePlan.individual]: individualPlan,
  [UsagePlan.team]: teamPlan,
  [UsagePlan.free]: freePlan,
  [UsagePlan.business]: businessPlan
}

const v2UsagePlans: Partial<Record<UsagePlan, UsagePlanItem>> = {
  [UsagePlan['Free-V2']]: freeV2Plan,
  [UsagePlan['Pay-As-You-Go']]: payAsYouGoPlan,
  [UsagePlan.Startup]: startupPlan,
  [UsagePlan.Pro]: proPlan,
  [UsagePlan['Enterprise-Low']]: enterpriseLow
}

const planTiers = [UsagePlan.free, UsagePlan.individual, UsagePlan.team, UsagePlan.business]

const initialFormValues = {
  plans: {
    usage: UsagePlan.free
  },
  payment_method_id: '',
  company_name: '',
  billing_email: '',
  phone_number: ''
}

const TeamBillingInfo: React.FC = () => {
  const { planPermission: showBilling, loading: loadingPermissions } = usePermissions(
    PermissionDomain.BILLING_1ST_PARTY,
    PermissionLevel.read
  )
  const history = useHistory()
  const userIdentity = useContext(UserIdentityContext)
  const selectedPlan = usagePlans[userIdentity.plan]
  const validPlan = Object.values(usagePlans).includes(selectedPlan)

  const dispatch = useDispatch()
  const { contactInfo } = useContactInfo()
  const { billingInfo, update } = useBillingInfo({
    revalidateOnFocus: false
  })
  const [isUpgrading, setIsUpgrading] = useState(false)
  const [isDowngrading, setIsDowngrading] = useState(false)

  const { getFieldProps, handleSubmit, values, setFieldValue } = useFormik<BillingInfo>({
    initialValues: billingInfo.data
      ? initializeForm({
          ...billingInfo.data,
          company_name: billingInfo.data.company_name ?? contactInfo.data?.company_name
        })
      : initialFormValues,
    enableReinitialize: true,
    onSubmit: async data => {
      const currentPlanTier = planTiers.findIndex(tier => tier === billingInfo.data.plans.usage)
      const newPlanTier = planTiers.findIndex(tier => tier === data.plans.usage)
      const isUpgrading = newPlanTier > currentPlanTier

      try {
        await update.call(data)
        isUpgrading
          ? setIsUpgrading(true)
          : dispatch(showAlert('Billing info saved with success!', 'success'))
      } catch (error) {
        if (error.response?.status === 403) {
          setIsDowngrading(true)
          return
        }

        dispatch(showAlert(getErrorMessage(error), 'error'))
      }
    }
  })

  const handleNewPaymentMethod = (source: BillingSource) => {
    setFieldValue('payment_method_id', source.id)
  }

  if (!billingInfo.data || loadingPermissions) {
    return (
      <div data-testid="billingLoader" className="loader">
        <Spinner />
      </div>
    )
  }

  const session: SessionCookieContents = SessionCookie.get()
  const v2Released = session.data?.feature_flags?.pricing_v2
  let plansToDisplay = v1UsagePlans
  if (v2Released) {
    if (!session.data?.user?.is_plan_deprecated) {
      plansToDisplay = v2UsagePlans
    }
  }

  return (
    <>
      <ContentHeader divider>Plans & Billing</ContentHeader>

      <div className="padding-y-3" />
      {showBilling && v2Released && <BetaAnnouncement message="Billing" />}
      {!showBilling && (
        <InfoBox
          body={
            <div>
              You are subscribed to Fauna with a third party such as AWS Marketplace. Please manage
              your plan and billing information in that location.
            </div>
          }
          iconName="info-circle"
          type={InfoBoxType.primary}
        />
      )}
      {showBilling && !v2Released && (
        <main>
          <section className="section section--no-border padding-top-3 billing__current-plan">
            <h2 className="section__title">Current Plan</h2>
            <CurrentPlan
              plan={usagePlans[billingInfo.data.plans.usage]}
              sourceId={billingInfo.data.payment_method_id}
            />
          </section>

          <section className="section section--no-border no-padding">
            <h2 className="section__title">Upgrade your plan</h2>
            <form className="billing" onSubmit={handleSubmit}>
              <div className="billing__left-col container container--xsmall">
                <section className="billing__section">
                  <a
                    className="margin-bottom-2 display-block"
                    href="https://fauna.com/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Compare all plans
                  </a>

                  <div className="billing__usage-plans">
                    {Object.values(plansToDisplay).map(plan => (
                      <PlanCard
                        key={plan.type}
                        planItem={plan}
                        selectedPlan={validPlan ? UsagePlan[`${selectedPlan}`] : values.plans.usage}
                        currentPlan={billingInfo.data.plans.usage}
                        onSelect={() => setFieldValue('plans.usage', plan.type)}
                      />
                    ))}
                  </div>

                  <div className="billing__usage-contact">
                    <div className="billing__usage-contact__panel">
                      <div className="billing__usage-contact__panel__header">Custom plans</div>

                      <div className="billing__usage-contact__panel__body">
                        <p>Looking for volume discounts, enhanced support?</p>
                        <p>
                          {' '}
                          <a
                            href="https://www2.fauna.com/contact-us"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Contact Us
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className="billing__right-col container container--xsmall">
                <section className="billing__section">
                  <h3 className="billing__section__title">Summary</h3>
                  <Summary usagePlan={usagePlans[values.plans.usage]} />
                </section>

                <section className="billing__section">
                  <h3 className="billing__section__title">Payment methods</h3>
                  <PaymentMethods
                    onAdd={handleNewPaymentMethod}
                    value={values.payment_method_id}
                    onChange={value => setFieldValue('payment_method_id', value)}
                  />
                </section>

                <section className="billing__section">
                  <div className="form-group">
                    <label htmlFor="company_name" className="required">
                      Company name
                    </label>
                    <input required id="company_name" {...getFieldProps('company_name')} />
                  </div>

                  <div className="form-group">
                    <label htmlFor="billing_email" className="required">
                      Billing email
                    </label>
                    <input
                      required
                      id="billing_email"
                      {...getFieldProps('billing_email')}
                      type="email"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone_number" className="required">
                      Phone number
                    </label>
                    <PhoneInput
                      required
                      name="phone_number"
                      id="phone_number"
                      value={values.phone_number}
                      onChange={value => setFieldValue('phone_number', value)}
                      defaultCountry="US"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-checkbox">
                      <label htmlFor="acceptTermsOfService">
                        <input
                          required
                          type="checkbox"
                          name="acceptTermsOfService"
                          id="acceptTermsOfService"
                        />
                        <span>
                          By checking this box, I acknowledge that I have read, understand, and
                          agree to be bound to{' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://fauna.com/terms"
                          >
                            Fauna’s Terms of Service
                          </a>
                          , and{' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://fauna.com/privacy"
                          >
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <p className="billing__fine-print">
                      Please note that your Fauna subscription renews on an automatic basis. To
                      cancel your Fauna subscription, please{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www2.fauna.com/contact-us"
                      >
                        contact us
                      </a>{' '}
                      prior to the last day of the calendar month or your subscription will
                      automatically renew for an additional month.
                    </p>
                  </div>
                </section>

                <section className="billing__actions">
                  <Button onClick={() => history.goBack()} color="secondary">
                    {tx('actions.cancel')}
                  </Button>

                  <Button
                    disabled={v2Released}
                    type="submit"
                    color="success"
                    loading={update.status === RequestStatus.loading}
                    data-testid="submitBillingForm"
                  >
                    {tx('actions.save')}
                  </Button>
                </section>
              </div>
            </form>
          </section>

          {/**
           * Used to render new payment form outside of the main form
           * This can be probably fixed when we use reach-ui/modal
           * wich already handle portals
           */}
          <Outlet outletId="afterPaymentForm" />
          <DowngradeModal isOpen={isDowngrading} onClose={() => setIsDowngrading(false)} />
          <UpgradeModal
            isOpen={isUpgrading}
            onClose={() => setIsUpgrading(false)}
            plan={billingInfo.data.plans.usage}
          />
        </main>
      )}
    </>
  )
}

function DowngradeModal({ onClose, isOpen }: Pick<ModalProps, 'onClose' | 'isOpen'>) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose}>Plan downgrade</ModalHeader>
      <ModalBody>
        <p>For security purposes, we do not provide self-serve plan downgrades at this time.</p>

        <p>
          Contact <a href="mailto:support@fauna.com">support@fauna.com</a> to downgrade your plan.
        </p>
      </ModalBody>
    </Modal>
  )
}

function UpgradeModal({
  onClose,
  isOpen,
  plan
}: Record<'plan', UsagePlan> & Pick<ModalProps, 'onClose' | 'isOpen'>) {
  const selectedPlan = usagePlans[plan]
  const upgradingToIndividualTier = plan === UsagePlan.individual

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="modal--upgrade">
      <ModalClose onClick={onClose} />
      <ModalBody className="text-center">
        <h2 className="secondary margin-bottom-3">Congrats!</h2>
        <p>Your account has been successfully updated to the {selectedPlan.name} plan.</p>
        {upgradingToIndividualTier && (
          <button
            className="btn btn-success btn-inline"
            data-testid="closeUpgradeModal"
            onClick={onClose}
          >
            Close window
          </button>
        )}

        {!upgradingToIndividualTier && (
          <Link
            className="btn btn-success btn-inline"
            to={routes.settings.members.path}
            data-testid="addTeamMembers"
          >
            Add team members
          </Link>
        )}
      </ModalBody>
    </Modal>
  )
}

export default withBreadcrumbs(() => [
  {
    label: 'Settings',
    path: routes.settings.profile.path
  },
  {
    label: 'Billing Info',
    path: routes.teams.billing.path
  }
])(TeamBillingInfo)
